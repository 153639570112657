<template>
  <div>
    <data-entry-main></data-entry-main>
  </div>
</template>

<script>
import DataEntryMain from "@/components/ecologist/dataEntry/dataEntryMain";
export default {
  name: "dataEntryMainView",
  components: { DataEntryMain },
};
</script>

<style scoped></style>
