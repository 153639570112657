<template>
  <div>
    <h2>Внесение данных</h2>
    <el-divider></el-divider>
    <h4>Результаты поиска</h4>
    <data-entry-table></data-entry-table>
  </div>
</template>

<script>
import DataEntryTable from "@/components/ecologist/dataEntry/dataEntryTable";
export default {
  name: "dataEntryMain",
  components: { DataEntryTable },
};
</script>

<style scoped></style>
